import React, {useState, useEffect} from 'react'
import "../Styles/Footer.css"
import { Container,  Form, Row, Col } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import LazyLoad from 'react-lazyload';
import laptopImg from "../Images/enterpriselaptop.png"
import talkdev from "../Images/TalkDEVit.png"
import talkmar from "../Images/TalkMartechit.png"
import talkfin from "../Images/TalkFintechit.png"
import talkit from "../Images/ETit.png"
import talkcmo1 from "../Images/TCMOit.png"
import facebook from "../Images/facebook-f.png"
import instagram from "../Images/instagram.png"
import linkedin from "../Images/linkedin.png"
import news6 from "../Images/news6.png";
// import { Link } from 'react-router-dom';
import {API_ROOT,webPath} from "../apiConfig";

const Footer = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);




  
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [policyText, setPolicyText] = useState(
    '*By clicking on the Submit button, you are agreeing with the Privacy Policy with Enterprise Talks.'
  );

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setIsValidEmail(isValid);
    return isValid;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const resetForm = () => {
    setEmail('');
    setIsValidEmail(true);
    setPolicyText(
      '*By clicking on the Submit button, you are agreeing with the Privacy Policy with Enterprise Talks.'
    );
  };

  const handleSubmit = async () => {
    if (validateEmail()) {
      try {
        const response = await fetch(`${API_ROOT}/api/subscribe/add`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {
          // The request was successful, you can handle the response here
          console.log('Subscription successful');
          resetForm(); // Reset the form after successful submission
          setPolicyText('Thank you for subscribing ✅');

          // Reset the complete form after 5 seconds
          setTimeout(() => {
            resetForm();
          }, 5000);
        } else {
          // The request failed, handle the error
          console.error("Subscription failed");
  
          // Check if the user already exists (assuming a specific response status code)
          // setTimeout(() => {
          //   resetForm();
          // }, 5000);
          if (response.status === 404) {
            // User already exists, show a message
            setPolicyText("This email is already subscribed ❌");
          }
        }
      } catch (error) {
        console.error('Error sending subscription request:', error);
      }
    } else {
      console.log('Invalid email');
    }
  };

  const [className5, setClassName5] = useState('');
  
  useEffect(() => {
    const sections = 5; 
    let currentSection = 1;

    const setSectionClassName = () => {
      const removeTimer = setTimeout(() => {
        setClassName5('');
      }, 0);

      const setTimer = setTimeout(() => {
        setClassName5('loaded5');
        currentSection++;

        if (currentSection <= sections) {
          setSectionClassName();
        } else {
          // Remove scroll event listener if currentSection * 290 is reached
          window.removeEventListener('scroll', handleScroll);
        }
      }, currentSection * 290);

      return () => {
        clearTimeout(removeTimer);
        clearTimeout(setTimer);
      };
    };

    const handleScroll = () => {
      // Add your logic here to determine when to trigger the code based on scroll
      if (window.scrollY > 290) { // Example condition: execute code when scrolled more than 100 pixels
        setSectionClassName();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 



  return (
    <div>

<LazyLoad className={className5}>

<div className="footerPadding" style={{backgroundColor:"#000"}}>
  <div className="container container-max">
    <div className="row">
      <div className="col-md-3">
        <div>
          <img className="FootLogo" src="https://itsecuritywire.com/wp-content/uploads/2020/04/ITSECURITYWIRE-LOGO-W.png" alt="Enterprise Logo" />
        </div>
        <div className="mt-4">
          <p className="just-text text-white">An invaluable resource for all your IT security initiatives and assets.</p>
        </div>
        <div className="mt-2">
          <p className="just-text text-white">Knowledge sharing platform for all IT security needs and plans. Peer to peer conversations that leverage industry experts and leaders for ideas, opinions and business insights.</p>
        </div>
        <div className="mt-3">
          <div>
            <a className="text-white fw-bold hoverHead" href="mailto: media@ITSecurityWire.com">Media@ITSecurityWire.com</a>
          </div>
          <div className='mt-3'>
            <a className="text-white fw-bold hoverHead" href="mailto: sales@ITSecurityWire.com">Sales@ITSecurityWire.com</a>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="">
          <div className="border-bottom-dotted mt-3">
            <h6 className="fw-bold h5 text-white">About</h6>
          </div>
          <div className="border-bottom-dotted mt-3">
            <p ><a href="/about" className='text-white hoverHead'>About Us</a></p>
          </div>
          <div className="border-bottom-dotted mt-3">
            <a href="/connect"><p className='hoverHead text-white'>Contact Us</p></a>
          </div>
          <div className="border-bottom-dotted mt-3">
            <p className='hoverHead'>
            <p className='text-white hoverHead DesktopResponsive' style={{cursor:"pointer"}} onClick={handleShow}>
        Subscribe
      </p>

      <div className="subscribePopUp">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{backgroundColor:"#000", border:"1px solid #fff"}}>
        <img className="subslogo" style={{width:"20%"}} src="https://itsecuritywire.com/wp-content/uploads/2020/04/ITSECURITYWIRE-LOGO-W.png" alt="Footer Logo" />
        {/* <Modal.Title style={{textAlign:"center", margin:"auto", fontWeight:"bold"}}>Subscribe to Newsletter</Modal.Title> */}

        </Modal.Header>
        <Container className="popupBack">
          <Row>
            <Col  md={5} className="mb-3 laptopmImgback">
              {/* <img className="DesktopResponsive mt-3" style={{width:"100%", height:"-webkit-fill-available" ,objectFit:"cover", borderRadius:"10px"}} src="https://www.alexhyett.com/static/674af38197248298565547353d5a5069/5f237/newsletter-iphone.png" alt="" /> */}
              <img className="DesktopResponsive mt-3" style={{width:"100%" ,objectFit:"cover", borderRadius:"10px"}} src={laptopImg} alt="" />

            </Col>
         

            <Col md={7}>
                         <div style={{textAlign:"center"}}>
                         <img className="mt-3" style={{width:"40%", borderRadius:"10px", filter: 'brightness(0) saturate(100%) invert(14%) sepia(100%) saturate(1000%) hue-rotate(345deg)' }} src={news6} alt="" />
                         </div>
                         
                          <Form.Group className="px-3 mb-3" controlId="exampleForm.ControlInput1">
        <Form.Control
          type="email"
          placeholder="Email ID"
          className={`mt-3 py-3 ${isValidEmail ? '' : 'is-invalid'}`}
          value={email}
          onChange={handleEmailChange}
        />
        <div className="mt-3 px-2 fw-bold" style={{ fontSize: '14px' }}>
        {policyText}
        </div> 
        
        {!isValidEmail && <div className="invalid-feedback ">Please enter a valid email address.</div>}
      </Form.Group>
      <Modal.Footer className="start">
        <button className="SubBtn" onClick={handleSubmit}>
          Submit
        </button>
      </Modal.Footer>
            </Col>
          </Row>
         
        </Container>

        
        
      </Modal>
      </div>
            </p>
          </div>
          {/* <div className="border-bottom-dotted mt-3">
            <p className='hoverHead'>Advertise</p>
          </div> */}
        </div>
      </div>
      <div className="col-md-3">
        <div className="">
          <div className="border-bottom-dotted mt-3">
            <h6 className="fw-bold h5 text-white">Policies</h6>
          </div>
          <div className="border-bottom-dotted mt-3">
           <a href='/privacy'> <p className='hoverHead text-white'>Privacy Policy</p></a>
          </div>
          {/* <div className="border-bottom-dotted mt-3">
            <p className='hoverHead'>Terms Of Use</p>
          </div> */}
          <div className="border-bottom-dotted mt-3">
           <a href="/opt-out-form"> <p className='hoverHead text-white'>Do Not Sell My Information</p> </a>
          </div>
        </div>
      </div>
      <div className="col-md-3 followPadding">
        <div>
          <p className="fw-bold text-white" style={{fontSize:"19px"}}>Follow Us</p>
        </div>


        <div className="d-flex mt-2">
          <div style={{width:"18%", borderRadius:"5px", border:"1px solid white"}} className='facebookImg'>
            <a href="https://www.facebook.com/ITSecWire"><img src={facebook} alt="facebook" style={{width:"10px"}} /></a>
          </div>
          {/* <div style={{width:"18%" , borderRadius:"5px", border:"1px solid white"}} className='facebookImg'>
          <a href="https://www.instagram.com/enterprisetalk"><img src={instagram} alt="instagram" style={{width:"15px"}} /></a>
          </div> */}
          <div style={{width:"18%" , borderRadius:"5px", border:"1px solid white"}} className='facebookImg'>
            <a  href="https://twitter.com/ITSecurityWire"><img src="https://cdn.punchng.com/wp-content/uploads/2023/07/24084806/Twitter-new-logo.jpeg" alt="twitter img" style={{width:"70%", borderRadius:"6px"}} /></a>
          </div>
          <div style={{width:"18%" , borderRadius:"5px", border:"1px solid white"}} className='facebookImg'>
            <a href="https://www.linkedin.com/company/itsecuritywire/"><img src={linkedin} alt="linkedin" style={{width:"15px"}} /></a>
          </div>
        </div>


      </div>
    </div>
    <div className="col-md-12 mt-3">
      <h6 className="text-center h5 fw-bold text-white">Visit Our Other Publications</h6>
    </div>


  <div className="d-flex justify-content-center text-center otherPublic">
  <div>
    <a href="https://enterprisetalk.com/"><img className='imgPublicaEnter' src={talkit} alt="talkit"  /></a>
  </div>
  <div>
    <a href="https://talkmartech.com/"><img className='imgPublica' src={talkmar} alt="talkmar"  /></a>
  </div>
  <div>
    <a href="https://talkfintech.com/"><img className='imgPublica' src={talkfin} alt="talkfin"  /></a>
  </div>
  <div>
    <a href="https://talkcmo.com/"><img className='imgPublicaCMO' src={talkcmo1} alt="talkcmo" /></a>
  </div>
  <div>
    <a href="https://talkdev.com/"><img className='imgPublicadev' src={talkdev} alt="talkdev"  /></a>
  </div>
  </div>



    <div className="mt-4" style={{borderTop:"1px solid #bdbdbd"}}>
      <p className="mt-2 text-white" style={{fontSize:" 13px"}}>An Imprint of OnDot ® Media © | All Rights Reserved | <a href="/privacy" className='text-white hoverHead pRed'>Privacy Policy</a></p>
    </div>
  </div>
</div>
</LazyLoad>


    </div>
  )
}

export default Footer